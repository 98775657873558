import React from 'react';

function TcoinbotButton() {
    return (
        <button id="btnTcoinbot" className="btn btn-primary animate-up-2 me-2" data-bs-toggle="modal" data-bs-target="#modalTcoinbot">
            <img src="/img/favicon/favicon-32x32.png" height="20" width="20" alt="Tcoinbot Logo" />
        </button>
    )
}

export default TcoinbotButton;